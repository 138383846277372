import Image from 'next/image';
import ellipseIcon from '@/images/ellipse.svg';

export function Loader() {
  return (
    <Image
      src={ellipseIcon}
      alt="Loading"
      width={32}
      height={32}
      className="absolute inset-0 m-auto animate-spin"
    />
  );
}
