'use client';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useEscapeEvent } from '../../hooks/useEscapeEvent';
import cx from 'classnames';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace globalThis {
  const instgrm: {
    Embeds: {
      process: () => void;
    };
  };
}

interface EmbeddedPostProps {
  open: boolean;
  html?: string;
  api?: string;
  onClose?: () => void;
}

export function EmbeddedPost({ open, api, html, onClose }: EmbeddedPostProps) {
  const embedRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const close = useCallback(() => {
    if (!embedRef.current) {
      return;
    }

    embedRef.current.innerHTML = '';
    setIsLoaded(false);
    onClose?.();
  }, [embedRef.current, onClose]);

  useEscapeEvent(close, open);

  useEffect(() => {
    if (!html || !embedRef.current) {
      return;
    }

    embedRef.current.innerHTML = html;
    if (api === 'Instagram') {
      globalThis.instgrm.Embeds.process();

      const iframe = embedRef.current.querySelector('iframe');
      iframe?.addEventListener('load', () => setIsLoaded(true));
    } else if (api === 'TikTok') {
      const existingScripts = document.querySelectorAll(
        'script[src="https://www.tiktok.com/embed.js"]'
      );
      if (existingScripts.length > 0) {
        // Remove the existing script to re-trigger TikTok embed
        existingScripts.forEach((existingScript: Element) =>
          existingScript.remove()
        );
      }

      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.tiktok.com/embed.js';
      document.body.appendChild(script);

      setTimeout(() => {
        setIsLoaded(true);
      }, 800);
    }
  }, [html, api]);

  return (
    <div
      className={cx('fixed inset-0 z-10 flex items-center justify-center', {
        hidden: !open
      })}
      data-testid="embedded-post"
    >
      <div
        className="absolute inset-0 z-10 bg-[#00000072]"
        onClick={close}
        onKeyUp={(e) => e.key === 'Escape' && close()}
        role="button"
        tabIndex={0}
      ></div>
      <div
        ref={embedRef}
        data-testid="embedded-post-wrapper"
        className={cx(
          `embedded-post embedded-${api?.toLowerCase()}-post z-20`,
          {
            invisible: !isLoaded,
            visible: isLoaded
          }
        )}
      ></div>
      {!isLoaded && api === 'Instagram' && (
        <div className="absolute z-30 m-auto flex h-[600px] w-80 flex-col justify-between rounded-sm bg-white">
          <div className="flex p-[10px]">
            <div className="gradient h-10 w-10 rounded-full"></div>
            <div className="ml-4 flex flex-col justify-around">
              <div className="gradient mb-1 h-3 w-24 rounded-sm"></div>
              <div className="gradient h-3 w-14 rounded-sm"></div>
            </div>
          </div>
          <div className="gradient gradient w-full flex-grow rounded-sm"></div>
          <div className="p-4">
            <div className="gradient mb-3 h-3 w-52 rounded-sm"></div>
            <div className="gradient h-3 w-36 rounded-sm"></div>
          </div>
        </div>
      )}
      {!isLoaded && api === 'TikTok' && (
        <div className="absolute z-30 m-auto flex h-[739px] w-[325px] max-w-sm flex-col overflow-hidden rounded-lg bg-white shadow-lg">
          <div className="gradient h-full w-full" />
          <div className="h-11" />
          <div className="flex w-full flex-col flex-nowrap content-start px-3 pb-[14px]">
            <div className="gradient mt-3 h-8 w-32" />
            <div className="gradient mt-2 h-10 w-full" />
            <div className="gradient mt-2 h-5 w-40"></div>
          </div>
        </div>
      )}
    </div>
  );
}
